import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from "@/routes";

import { getUserData, isUserLoggedIn } from "@/auth";

Vue.use( VueRouter );

const router = new VueRouter( {
	mode: "history",
	routes
} );

router.beforeEach( ( to, from, next ) => {

	if (to.name === from.name) {
		return;
	}

	const isLoggedIn = isUserLoggedIn();
	const user = getUserData();

	if ( to.name === "auth-login" ) {
		if ( isLoggedIn ) {
			return next( { name: "dashboard" } );
		}
	} else {
		if ( !isLoggedIn ) {
			return next( { name: "auth-login" } );
		}
	}

	const permission = to.meta.permission || null;

	if (permission && !user.allowed(permission)) {
		return next( { name: "pageNotFound" } );
	}

	return next();
} );

export default router;
