import HomeIcon from "@/components/icons/HomeIcon";
import DietIcon from "@/components/icons/DietIcon";

import { logout } from "@/auth";

export default [
	{
		icon: HomeIcon,
		title: 'Dashboard',
		component: () => import("@/views/dashboard/DashboardView"),
		path: '/dashboard',
	},
	{
		icon: DietIcon,
		title: 'Food',
		component: () => import("@/views/food/FoodIndex"),
		path: '/food',
		permission: "food",
	},
	{
		icon: DietIcon,
		title: 'Users',
		component: () => import("@/views/users/UsersView"),
		path: '/users',
		permission: "user",
	},
	{
		icon: DietIcon,
		title: 'Trainings',
		component: () => import("@/views/training/TrainingsView"),
		path: '/trainings',
		permission: "training",
	},
	{
		icon: DietIcon,
		title: 'Statistics',
		path: '/statistics',
		permission: "statistics",
        children: [
            {
                icon: DietIcon,
                title: 'Users',
                component: () => import("@/views/stats/UserStats"),
                path: '/statistics/users',
            },
            {
                icon: DietIcon,
                title: 'Payments',
                component: () => import("@/views/stats/PaymentStats"),
                path: '/statistics/payments',
            },
        ],
	},
	{
		icon: DietIcon,
		title: 'Stories',
		component: () => import("@/views/stories/StoriesView"),
		path: '/stories',
		permission: "story",
	},
	{
		icon: DietIcon,
		title: 'Stripe',
		component: () => import("@/views/stripe/StripeView"),
		path: '/stripe',
		permission: "stripe",
	},
	{
		icon: DietIcon,
		title: 'Translation',
		component: () => import("@/views/translation/TranslationView"),
		path: '/translation',
		permission: "translation",
	},
	{
		icon: DietIcon,
		title: 'Tasty',
		component: () => import("@/views/tasty/TastyView"),
		path: '/tasty',
		permission: "tasty",
	},
	{
		icon: DietIcon,
		title: 'Logout',
		click: () => logout(),
	},
];
