export default [
	{
		id: 1,
		code: 'minimal',
		name: '',
		desc: 'Minimal activity, sedentary lifestyle',
	},
	{
		id: 2,
		code: 'light',
		name: '',
		desc: 'Light exercise 1-3 times a week',
	},
	{
		id: 3,
		code: '1-3-times',
		name: '',
		desc: 'I train 1-3 times a week',
	},
	{
		id: 4,
		code: '3-5-times',
		name: '',
		desc: 'I train 3-5 times a week',
	},
	{
		id: 5,
		code: 'every-day',
		name: '',
		desc: 'I train every day',
	},
	{
		id: 6,
		code: 'hard-work',
		name: '',
		desc: 'Hard physical work + active trainings',
	},
];
