export default [
	{
		"id": 1,
		"name": "Lose weight",
		"desc": "Without strict diets and grueling workouts"
	},
	{
		"id": 2,
		"name": "Maintain weight",
		"desc": "Without strict diets and grueling workouts"
	},
	{
		"id": 3,
		"name": "Put on weight",
		"desc": "Without strict diets and grueling workouts"
	},
	{
		"id": 4,
		"name": "Gain muscle mass",
		"desc": "Without strict diets and grueling workouts"
	}
];
