export default [
	{
		"id": 1,
		"code": "male",
		"name": "Male"
	},
	{
		"id": 2,
		"code": "female",
		"name": "Female"
	}
];
