export default [
	{
		id: 1,
		code: 'less-30-min',
		name: '',
		desc: 'No more than 30 minutes',
	},
	{
		id: 2,
		code: '30-60-min',
		name: '',
		desc: '30-60 minutes',
	},
	{
		id: 3,
		code: 'more-60-min',
		name: '',
		desc: 'More than 60 minutes',
	},
];
