<template>
	<div class="main-layout">

		<div class="main-layout__sidebar">
			<Sidebar :items="items">
				<a class="bf-sidebar__logo">
					<img src="@/assets/img/landing/logo.svg" alt="Logo" class="bf-sidebar__logo-img">
				</a>
			</Sidebar>
		</div>

		<div class="main-layout__content">
			<slot/>
		</div>

	</div>
</template>

<script>
import "@/assets/scss/layouts/main-layout.scss";

import Sidebar from "@/components/view-block/Sidebar";

import sidebar from "@/navigation/sidebar";
import app from "@/mixins/app";

export default {
	name: "MainLayout",
	mixins: [ app ],
	components: {
		Sidebar,
	},
	data: () => ({}),
	computed: {
		items() {
			return sidebar || [];
		}
	},
	mounted() {

	}
};
</script>

<style lang="scss">

</style>
