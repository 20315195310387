<template>
	<div class="bf-sidebar__items">
		<div
			v-for="(item, idx) in filteredItems"
			:key="item.name"
			class="bf-sidebar__item"
			:class="classState( item, idx )"
			@click="evt => onLinkClick(evt, item)"
		>
			<a
				class="bf-sidebar__link"
			>
				<component
					v-if="item.icon"
					:is="item.icon"
					class="bf-sidebar__item-icon"
				/>

				<span class="bf-sidebar__item-text">{{ item.title }}</span>
			</a>

			<SidebarItems
				v-if="item.children && Array.isArray(item.children)"
				class="bf-sidebar__submenu"
				:items="item.children"
			/>

		</div>
	</div>
</template>

<script>
export default {
	name: "SidebarItems",
	props: {
		items: {
			type: Array,
			default: () => ([]),
		},
	},
	data: () => ({}),
	computed: {
		filteredItems() {
			return this.items.filter( item => item.permission ? this.user.can().viewAny( item.permission ) : true );
		},
	},
	methods: {
		onLinkClick( evt, item ) {
			if ( item.children && Array.isArray( item.children ) ) {
				evt.srcElement.closest(".bf-sidebar__item").classList.toggle("bf-sidebar__item--active");
				return;
			}

			if ( item.click ) {
				item.click();
			}

			if ( item.path ) {
				this.$router.push( item.path ).catch(err => {});
			}
		},
		classState( item ) {
			let active = false;

			if ( item.children && Array.isArray( item.children ) ) {
				active = this.$route.path.includes( item.path );
			} else {
				active = this.$route.path === item.path;
			}

			return {
				'bf-sidebar__item--active': active,
			};
		},
	},
};
</script>

<style lang="scss" scoped>


</style>
