export default [
	{
		id: 1,
		code: 'traditional',
		name: 'Traditional',
		desc: 'TRADITIONAL. The simplest definition of vegetarianism is a diet free of meat, fish, and fowl flesh. But eating habits of vegetarians cover a wide spectrum. At one end are lacto-ovo vegetarians, who avoid animal flesh but eat eggs and milk products.',
	},
	{
		id: 2,
		code: 'ketogenic',
		name: 'Ketogenic',
		desc: 'KETOGENIC. The simplest definition of vegetarianism is a diet free of meat, fish, and fowl flesh. But eating habits of vegetarians cover a wide spectrum. At one end are lacto-ovo vegetarians, who avoid animal flesh but eat eggs and milk products.',
	},
	{
		id: 3,
		code: 'cuisine',
		name: 'Сuisine',
		desc: 'СUISINE. The simplest definition of vegetarianism is a diet free of meat, fish, and fowl flesh. But eating habits of vegetarians cover a wide spectrum. At one end are lacto-ovo vegetarians, who avoid animal flesh but eat eggs and milk products.',
	},
	{
		id: 4,
		code: 'lacto-vegetarian',
		name: 'Lacto-Vegetarian',
		desc: 'The simplest definition of vegetarianism is a diet free of meat, fish, and fowl flesh. But eating habits of vegetarians cover a wide spectrum. At one end are lacto-ovo vegetarians, who avoid animal flesh but eat eggs and milk products.',
	},
	{
		id: 5,
		code: 'vegan',
		name: 'Vegan',
		desc: 'The simplest definition of vegetarianism is a diet free of meat, fish, and fowl flesh. But eating habits of vegetarians cover a wide spectrum. At one end are lacto-ovo vegetarians, who avoid animal flesh but eat eggs and milk products.',
	},
	{
		id: 6,
		code: 'vegan-2',
		name: 'Vegan 2',
		desc: 'The simplest definition of vegetarianism is a diet free of meat, fish, and fowl flesh. But eating habits of vegetarians cover a wide spectrum. At one end are lacto-ovo vegetarians, who avoid animal flesh but eat eggs and milk products.',
	},
];
