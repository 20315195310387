import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
	components: {},
	props: {},

	computed: {
		...mapGetters( "app", [
			"user",
			"loading",
			"userLoaded",
		] ),
	},
	methods: {
		...mapActions( "app", [
			"fetchUser",
		] ),
		...mapMutations( "app", [
			"setLoading",
		] ),
	},
};
