import router from "@/router";

import User     from "@/models/User";

export const isUserLoggedIn = () => {
	return localStorage.getItem( 'user' ) && localStorage.getItem( "access_token" );
};

export const getUserData = () => {
	const json = localStorage.getItem( 'user' );

	return json ? new User( JSON.parse( json ) ) : {};
};

export const getAccessToken = () => localStorage.getItem( "access_token" );

export const logout = () => {

	fetch( `/api/auth/logout`, { method: 'POST' } ).finally( () => {
		localStorage.removeItem( "access_token" );
		localStorage.removeItem( "user" );
		router.push( "/login" );
	} );
};
