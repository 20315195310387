import Vue    from 'vue';
import App    from './App.vue';

import "@/globals";
import './registerServiceWorker';
import router from './router';
import store  from './store';

Vue.config.productionTip = false;

import global from "@/mixins/global";
Vue.mixin( global );

import PrimeVue from 'primevue/config';
Vue.use( PrimeVue );

import { Form } from "vform";
import api      from "@/api";
Form.axios = api;

window.Form = Form;

import "@/assets/scss/core.scss";
import "@/assets/scss/components/bf-datepicker.scss";

import "primevue/resources/themes/saga-blue/theme.css";       //theme
import "primevue/resources/primevue.min.css";                 //core css
import "primeflex/primeflex.min.css";                 //core css
import "primeicons/primeicons.css";                           //icons

new Vue( {
	router,
	store,
	render: h => h( App )
} ).$mount( '#app' );


