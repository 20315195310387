import { BContainer, BCol, BOverlay, BRow } from "bootstrap-vue";

import { mapActions, mapGetters, mapMutations } from "vuex";

import parameters from "@/db/parameters";

import Measure from "@/models/Measure";
const measure = new Measure(parameters);

const url = window.location.hostname === "localhost" ? "http://adminbf.loc" : window.location.origin;

export default {
	components: {
		BContainer,
		BCol,
		BOverlay,
		BRow,
	},
	props: {},
	computed: {
		...mapGetters( "app", [
			"user",
			"userLoaded",
		] ),
		unit() {
			measure.unitSystem = this.user.unitSystem;

			return measure;
		},
		appUrl() { return url },
	},
	methods: {
		hasSlot( name ) {
			return !!this.$slots[name] || !!this.$scopedSlots[name];
		},
		getDate( date ) {
			return getDate( date );
		},
		formatDate( date, separator ) {
			return formatDate( date, separator );
		},
		getProp( obj, key ) {
			return window.getProp( obj, key );
		},
		...mapActions( "app", [
			"fetchUser",
		] ),
		...mapMutations( "app", [
			"setLoading",
		] ),
	},
	mounted() {

	}
};
