<template>
	<svg class="svg-icon --fill" width="20" height="20" viewBox="0 0 20 20">
		<path d="M16.6666 6.66643L11.6666 2.28309C11.2083 1.87313 10.6149 1.64648 9.99996 1.64648C9.38501 1.64648 8.79164 1.87313 8.33329 2.28309L3.33329 6.66643C3.0686 6.90316 2.85737 7.19356 2.71368 7.5183C2.56999 7.84304 2.49713 8.19466 2.49996 8.54976V15.8331C2.49996 16.4961 2.76335 17.132 3.23219 17.6009C3.70103 18.0697 4.33692 18.3331 4.99996 18.3331H15C15.663 18.3331 16.2989 18.0697 16.7677 17.6009C17.2366 17.132 17.5 16.4961 17.5 15.8331V8.54143C17.5016 8.18773 17.4282 7.83771 17.2845 7.5145C17.1409 7.19128 16.9303 6.90222 16.6666 6.66643ZM11.6666 16.6664H8.33329V12.4998C8.33329 12.2787 8.42109 12.0668 8.57737 11.9105C8.73365 11.7542 8.94561 11.6664 9.16662 11.6664H10.8333C11.0543 11.6664 11.2663 11.7542 11.4225 11.9105C11.5788 12.0668 11.6666 12.2787 11.6666 12.4998V16.6664ZM15.8333 15.8331C15.8333 16.0541 15.7455 16.2661 15.5892 16.4223C15.4329 16.5786 15.221 16.6664 15 16.6664H13.3333V12.4998C13.3333 11.8367 13.0699 11.2008 12.6011 10.732C12.1322 10.2632 11.4963 9.99976 10.8333 9.99976H9.16662C8.50358 9.99976 7.8677 10.2632 7.39886 10.732C6.93002 11.2008 6.66662 11.8367 6.66662 12.4998V16.6664H4.99996C4.77894 16.6664 4.56698 16.5786 4.4107 16.4223C4.25442 16.2661 4.16662 16.0541 4.16662 15.8331V8.54143C4.16677 8.42311 4.19212 8.30617 4.24097 8.19841C4.28982 8.09065 4.36107 7.99452 4.44996 7.91643L9.44996 3.54143C9.60203 3.40783 9.79753 3.33415 9.99996 3.33415C10.2024 3.33415 10.3979 3.40783 10.55 3.54143L15.55 7.91643C15.6388 7.99452 15.7101 8.09065 15.7589 8.19841C15.8078 8.30617 15.8331 8.42311 15.8333 8.54143V15.8331Z"/>
	</svg>
</template>

<script>
export default {
	name: "HomeIcon"
};
</script>

<style scoped>

</style>
