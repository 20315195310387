import api from "@/api";

export default class Model {

	constructor( data ) {
		this.original = data;

		this.loading = false;
		this.loaded = false;
	}

	get api() {
		return api;
	}

	hasError() {

	}
}
