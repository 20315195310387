import Model from "@/models/Model";

import UserProgress from "@/models/UserProgress";

import unitSystems from "@/db/unitSystems";

import parameters from "@/db/parameters";

import Measure from "@/models/Measure";
import convert from "@/functions/convert";

const measure = new Measure( parameters );


/**
 * @class User
 * @property {Number} id
 * @property {string} name
 * @property {string} email
 */
export default class User extends Model {
	constructor( data = {} ) {

		super( data );

		this.loaded = new Promise( ( resolve, reject ) => {
			this.resolvePromise = resolve;
			this.rejectPromise = reject;
		} );

		this.roles = [];
		this.abilities = {};

		this.load( data );
	}

	load( data ) {
		Object.assign( this, { ...(data || {}) } );
	}

	isSuperAdmin() {
		return this.roles.find(item => item.name === "super_admin");
	}

	allowed( name ) {
		return this.isSuperAdmin() || this.abilities[name] || false;
	}

	can() {
		const isSuper = this.isSuperAdmin();

		return {
			viewAny: name => isSuper || this.abilities[`can_view_any_${ name }`] || false,
			view: name => isSuper || this.abilities[`can_view_${ name }`] || false,
			create: name => isSuper || this.abilities[`can_create_${ name }`] || false,
			update: name => isSuper || this.abilities[`can_update_${ name }`] || false,
			delete: name => isSuper || this.abilities[`can_delete_${ name }`] || false,
		};
	}
}
