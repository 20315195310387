<template>
	<div id="app">

		<component :is="CurrentLayout">

			<router-view/>

		</component>

	</div>
</template>
<script>

import MainLayout from "@/layouts/MainLayout";
import SignLayout from "@/layouts/SignLayout";

import app from "@/mixins/app";

import { getAccessToken } from "@/auth";

export default {
	mixins: [ app ],
	components: {
		MainLayout,
		SignLayout,
	},
	computed: {
		CurrentLayout() {
			const route = this.$route;

			const { layout } = this.$route.meta;

			if (layout) {
				return layout;
			}

			return MainLayout;
		}
	},
	data: () => ({

	}),
	created() {
		const accessToken = getAccessToken();

		if( accessToken ) {
			this.fetchUser();
		}
	}
};
</script>
