import SignLayout from "@/layouts/SignLayout";

import { isUserLoggedIn } from "@/auth";

import DietIcon from "@/components/icons/DietIcon";

export default [
	{
		path: '/',
		name: "app",
		redirect: () => {
			const isLoggedIn = isUserLoggedIn();

			if ( isLoggedIn ) {
				return { name: "dashboard" };
			}

			return { name: "login" };
		},
	},
	{
		path: '*',
		name: 'pageNotFound',
		component: () => import('@/views/errors/PageNotFoundView.vue'),
		meta: {},
	},
	// SIGN
	{
		path: '/login',
		name: 'auth-login',
		component: () => import('@/views/login/LoginView.vue'),
		meta: {
			layout: SignLayout,
		},
	},

	// MAIN LAYOUT START
	{
		path: '/dashboard',
		name: 'dashboard',
		component: () => import('@/views/dashboard/DashboardView.vue'),
		meta: {},
	},
	{
		path: '/food',
		name: 'foods',
		component: () => import("@/views/food/FoodIndex"),
		meta: {
			permission: "can_view_any_food",
		},
	},
	{
		path: '/tasty',
		name: 'tasty',
		component: () => import("@/views/tasty/TastyView"),
		meta: {

		},
	},
	{
		path: '/tasty-diet',
		name: 'tasty-diet',
		component: () => import("@/views/tasty-diet/TastyDietView"),
		meta: {

		},
	},
	{
		path: '/food/:id',
		name: 'food',
		component: () => import("@/views/food/FoodView"),
		meta: {
			permission: "can_view_food",
		},
	},
	{
		path: '/statistics/users',
		name: '/statistics-users',
		component: () => import("@/views/stats/UserStats"),
		meta: {
			permission: "can_view_any_statistics",
		},
	},
	{
		path: '/statistics/payments',
		name: '/statistics-payments',
		component: () => import("@/views/stats/PaymentStats"),
		meta: {
			permission: "can_view_any_statistics",
		},
	},
	{
		path: '/users',
		name: 'users',
		component: () => import("@/views/users/UsersView"),
		meta: {
			permission: "can_view_any_user",
		},
	},
	{
		path: '/users/:id',
		name: 'user',
		component: () => import("@/views/users/UserView"),
		meta: {
			permission: "can_view_user",
		},
	},
	{
		path: '/stories',
		name: 'stories',
		component: () => import("@/views/stories/StoriesView"),
		meta: {
			permission: "can_view_any_story",
		},
	},
	{
		path: '/trainings',
		name: 'trainings',
		component: () => import("@/views/training/TrainingsView"),
		meta: {
			permission: "can_view_any_training",
		},
	},
	{
		icon: DietIcon,
		title: 'Stripe',
		component: () => import("@/views/stripe/StripeView"),
		path: '/stripe',
		meta: {
			permission: "can_view_stripe",
		},
	},
	{
		icon: DietIcon,
		title: 'Translation',
		component: () => import("@/views/translation/TranslationView"),
		path: '/translation',
		meta: {
			permission: "can_view_translation",
		},
	},
	// MAIN LAYOUT END
];
