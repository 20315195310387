export default {
	"height": {
		"id": 1,
		"code": "height",
		"name": "Height",
		"measures": {
			"metric": {
				"id": 1,
				"parameter_id": 1,
				"unit_system_id": 1,
				"unit_id": 2,
				"unit": {
					"id": 2,
					"unit_system_id": 1,
					"unit_type_id": 1,
					"code": "cm",
					"name": "centimetre",
					"desc": null
				},
				"unit_system": { "id": 1, "code": "metric", "name": "Metric", "desc": null }
			},
			"us": {
				"id": 2,
				"parameter_id": 1,
				"unit_system_id": 2,
				"unit_id": 5,
				"unit": { "id": 5, "unit_system_id": 2, "unit_type_id": 1, "code": "ft", "name": "foot", "desc": null },
				"unit_system": { "id": 2, "code": "us", "name": "US", "desc": null }
			}
		}
	},
	"weight": {
		"id": 2,
		"code": "weight",
		"name": "Weight",
		"measures": {
			"metric": {
				"id": 3,
				"parameter_id": 2,
				"unit_system_id": 1,
				"unit_id": 10,
				"unit": {
					"id": 10,
					"unit_system_id": 1,
					"unit_type_id": 2,
					"code": "kg",
					"name": "kilograms",
					"desc": null
				},
				"unit_system": { "id": 1, "code": "metric", "name": "Metric", "desc": null }
			},
			"us": {
				"id": 4,
				"parameter_id": 2,
				"unit_system_id": 2,
				"unit_id": 12,
				"unit": {
					"id": 12,
					"unit_system_id": 2,
					"unit_type_id": 2,
					"code": "lb",
					"name": "pound",
					"desc": null
				},
				"unit_system": { "id": 2, "code": "us", "name": "US", "desc": null }
			}
		}
	},
	"weight_start": {
		"id": 3,
		"code": "weight_start",
		"name": "Weight Start",
		"measures": {
			"metric": {
				"id": 5,
				"parameter_id": 3,
				"unit_system_id": 1,
				"unit_id": 10,
				"unit": {
					"id": 10,
					"unit_system_id": 1,
					"unit_type_id": 2,
					"code": "kg",
					"name": "kilograms",
					"desc": null
				},
				"unit_system": { "id": 1, "code": "metric", "name": "Metric", "desc": null }
			},
			"us": {
				"id": 6,
				"parameter_id": 3,
				"unit_system_id": 2,
				"unit_id": 12,
				"unit": {
					"id": 12,
					"unit_system_id": 2,
					"unit_type_id": 2,
					"code": "lb",
					"name": "pound",
					"desc": null
				},
				"unit_system": { "id": 2, "code": "us", "name": "US", "desc": null }
			}
		}
	},
	"weight_ideal": {
		"id": 4,
		"code": "weight_ideal",
		"name": "Weight Ideal",
		"measures": {
			"metric": {
				"id": 7,
				"parameter_id": 4,
				"unit_system_id": 1,
				"unit_id": 10,
				"unit": {
					"id": 10,
					"unit_system_id": 1,
					"unit_type_id": 2,
					"code": "kg",
					"name": "kilograms",
					"desc": null
				},
				"unit_system": { "id": 1, "code": "metric", "name": "Metric", "desc": null }
			},
			"us": {
				"id": 8,
				"parameter_id": 4,
				"unit_system_id": 2,
				"unit_id": 12,
				"unit": {
					"id": 12,
					"unit_system_id": 2,
					"unit_type_id": 2,
					"code": "lb",
					"name": "pound",
					"desc": null
				},
				"unit_system": { "id": 2, "code": "us", "name": "US", "desc": null }
			}
		}
	},
	"breast": {
		"id": 5,
		"code": "breast",
		"name": "Breast",
		"measures": {
			"metric": {
				"id": 9,
				"parameter_id": 5,
				"unit_system_id": 1,
				"unit_id": 2,
				"unit": {
					"id": 2,
					"unit_system_id": 1,
					"unit_type_id": 1,
					"code": "cm",
					"name": "centimetre",
					"desc": null
				},
				"unit_system": { "id": 1, "code": "metric", "name": "Metric", "desc": null }
			},
			"us": {
				"id": 10,
				"parameter_id": 5,
				"unit_system_id": 2,
				"unit_id": 4,
				"unit": { "id": 4, "unit_system_id": 2, "unit_type_id": 1, "code": "in", "name": "inch", "desc": null },
				"unit_system": { "id": 2, "code": "us", "name": "US", "desc": null }
			}
		}
	},
	"biceps": {
		"id": 6,
		"code": "biceps",
		"name": "Biceps",
		"measures": {
			"metric": {
				"id": 11,
				"parameter_id": 6,
				"unit_system_id": 1,
				"unit_id": 2,
				"unit": {
					"id": 2,
					"unit_system_id": 1,
					"unit_type_id": 1,
					"code": "cm",
					"name": "centimetre",
					"desc": null
				},
				"unit_system": { "id": 1, "code": "metric", "name": "Metric", "desc": null }
			},
			"us": {
				"id": 12,
				"parameter_id": 6,
				"unit_system_id": 2,
				"unit_id": 4,
				"unit": { "id": 4, "unit_system_id": 2, "unit_type_id": 1, "code": "in", "name": "inch", "desc": null },
				"unit_system": { "id": 2, "code": "us", "name": "US", "desc": null }
			}
		}
	},
	"stomach": {
		"id": 7,
		"code": "stomach",
		"name": "Stomach",
		"measures": {
			"metric": {
				"id": 13,
				"parameter_id": 7,
				"unit_system_id": 1,
				"unit_id": 2,
				"unit": {
					"id": 2,
					"unit_system_id": 1,
					"unit_type_id": 1,
					"code": "cm",
					"name": "centimetre",
					"desc": null
				},
				"unit_system": { "id": 1, "code": "metric", "name": "Metric", "desc": null }
			},
			"us": {
				"id": 14,
				"parameter_id": 7,
				"unit_system_id": 2,
				"unit_id": 4,
				"unit": { "id": 4, "unit_system_id": 2, "unit_type_id": 1, "code": "in", "name": "inch", "desc": null },
				"unit_system": { "id": 2, "code": "us", "name": "US", "desc": null }
			}
		}
	},
	"waist": {
		"id": 8,
		"code": "waist",
		"name": "Waist",
		"measures": {
			"metric": {
				"id": 15,
				"parameter_id": 8,
				"unit_system_id": 1,
				"unit_id": 2,
				"unit": {
					"id": 2,
					"unit_system_id": 1,
					"unit_type_id": 1,
					"code": "cm",
					"name": "centimetre",
					"desc": null
				},
				"unit_system": { "id": 1, "code": "metric", "name": "Metric", "desc": null }
			},
			"us": {
				"id": 16,
				"parameter_id": 8,
				"unit_system_id": 2,
				"unit_id": 4,
				"unit": { "id": 4, "unit_system_id": 2, "unit_type_id": 1, "code": "in", "name": "inch", "desc": null },
				"unit_system": { "id": 2, "code": "us", "name": "US", "desc": null }
			}
		}
	},
	"thign": {
		"id": 9,
		"code": "thign",
		"name": "Thign",
		"measures": {
			"metric": {
				"id": 17,
				"parameter_id": 9,
				"unit_system_id": 1,
				"unit_id": 2,
				"unit": {
					"id": 2,
					"unit_system_id": 1,
					"unit_type_id": 1,
					"code": "cm",
					"name": "centimetre",
					"desc": null
				},
				"unit_system": { "id": 1, "code": "metric", "name": "Metric", "desc": null }
			},
			"us": {
				"id": 18,
				"parameter_id": 9,
				"unit_system_id": 2,
				"unit_id": 4,
				"unit": { "id": 4, "unit_system_id": 2, "unit_type_id": 1, "code": "in", "name": "inch", "desc": null },
				"unit_system": { "id": 2, "code": "us", "name": "US", "desc": null }
			}
		}
	},
	"calories": {
		"id": 10,
		"code": "calories",
		"name": "Calories",
		"measures": {
			"metric": {
				"id": 19,
				"parameter_id": 10,
				"unit_system_id": 1,
				"unit_id": 21,
				"unit": {
					"id": 21,
					"unit_system_id": null,
					"unit_type_id": 4,
					"code": "kcal",
					"name": "Calories",
					"desc": null
				},
				"unit_system": { "id": 1, "code": "metric", "name": "Metric", "desc": null }
			},
			"us": {
				"id": 20,
				"parameter_id": 10,
				"unit_system_id": 2,
				"unit_id": 21,
				"unit": {
					"id": 21,
					"unit_system_id": null,
					"unit_type_id": 4,
					"code": "kcal",
					"name": "Calories",
					"desc": null
				},
				"unit_system": { "id": 2, "code": "us", "name": "US", "desc": null }
			}
		}
	},
	"calories_burned": {
		"id": 11,
		"code": "calories_burned",
		"name": "Calories burned",
		"measures": {
			"metric": {
				"id": 21,
				"parameter_id": 11,
				"unit_system_id": 1,
				"unit_id": 21,
				"unit": {
					"id": 21,
					"unit_system_id": null,
					"unit_type_id": 4,
					"code": "kcal",
					"name": "Calories",
					"desc": null
				},
				"unit_system": { "id": 1, "code": "metric", "name": "Metric", "desc": null }
			},
			"us": {
				"id": 22,
				"parameter_id": 11,
				"unit_system_id": 2,
				"unit_id": 21,
				"unit": {
					"id": 21,
					"unit_system_id": null,
					"unit_type_id": 4,
					"code": "kcal",
					"name": "Calories",
					"desc": null
				},
				"unit_system": { "id": 2, "code": "us", "name": "US", "desc": null }
			}
		}
	},
	"water": {
		"id": 12,
		"code": "water",
		"name": "Water",
		"measures": {
			"metric": {
				"id": 23,
				"parameter_id": 12,
				"unit_system_id": 1,
				"unit_id": 14,
				"unit": {
					"id": 14,
					"unit_system_id": 1,
					"unit_type_id": 3,
					"code": "ml",
					"name": "millilitre",
					"desc": null
				},
				"unit_system": { "id": 1, "code": "metric", "name": "Metric", "desc": null }
			},
			"us": {
				"id": 24,
				"parameter_id": 12,
				"unit_system_id": 2,
				"unit_id": 16,
				"unit": {
					"id": 16,
					"unit_system_id": 2,
					"unit_type_id": 3,
					"code": "fl oz",
					"name": "fluid ounce",
					"desc": null
				},
				"unit_system": { "id": 2, "code": "us", "name": "US", "desc": null }
			}
		}
	},
	"steps": {
		"id": 13,
		"code": "steps",
		"name": "Steps",
		"measures": {
			"metric": {
				"id": 25,
				"parameter_id": 13,
				"unit_system_id": 1,
				"unit_id": 27,
				"unit": {
					"id": 27,
					"unit_system_id": null,
					"unit_type_id": 6,
					"code": "steps",
					"name": "Steps",
					"desc": null
				},
				"unit_system": { "id": 1, "code": "metric", "name": "Metric", "desc": null }
			},
			"us": {
				"id": 26,
				"parameter_id": 13,
				"unit_system_id": 2,
				"unit_id": 27,
				"unit": {
					"id": 27,
					"unit_system_id": null,
					"unit_type_id": 6,
					"code": "steps",
					"name": "Steps",
					"desc": null
				},
				"unit_system": { "id": 2, "code": "us", "name": "US", "desc": null }
			}
		}
	},

	// NUTRIENTS
	"carbohydrates": {
		"id": 2,
		"code": "carbohydrates",
		"name": "Carbohydrates",
		"measures": {
			"metric": {
				"id": 3,
				"nutrient_id": 2,
				"unit_system_id": 1,
				"unit_id": 9,
				"unit": {
					"id": 9,
					"unit_system_id": 1,
					"unit_type_id": 2,
					"code": "g",
					"name": "grams",
					"desc": null
				},
				"unit_system": {
					"id": 1,
					"code": "metric",
					"name": "Metric",
					"desc": "kg, cm, ml"
				}
			},
			"us": {
				"id": 4,
				"nutrient_id": 2,
				"unit_system_id": 2,
				"unit_id": 11,
				"unit": {
					"id": 11,
					"unit_system_id": 2,
					"unit_type_id": 2,
					"code": "oz",
					"name": "ounce",
					"desc": null
				},
				"unit_system": {
					"id": 2,
					"code": "us",
					"name": "US",
					"desc": "pound, inch, ounce"
				}
			}
		}
	},
	"protein": {
		"id": 3,
		"code": "protein",
		"name": "Protein",
		"measures": {
			"metric": {
				"id": 5,
				"nutrient_id": 3,
				"unit_system_id": 1,
				"unit_id": 9,
				"unit": {
					"id": 9,
					"unit_system_id": 1,
					"unit_type_id": 2,
					"code": "g",
					"name": "grams",
					"desc": null
				},
				"unit_system": {
					"id": 1,
					"code": "metric",
					"name": "Metric",
					"desc": "kg, cm, ml"
				}
			},
			"us": {
				"id": 6,
				"nutrient_id": 3,
				"unit_system_id": 2,
				"unit_id": 11,
				"unit": {
					"id": 11,
					"unit_system_id": 2,
					"unit_type_id": 2,
					"code": "oz",
					"name": "ounce",
					"desc": null
				},
				"unit_system": {
					"id": 2,
					"code": "us",
					"name": "US",
					"desc": "pound, inch, ounce"
				}
			}
		}
	},
	"fat": {
		"id": 4,
		"code": "fat",
		"name": "Fat",
		"measures": {
			"metric": {
				"id": 7,
				"nutrient_id": 4,
				"unit_system_id": 1,
				"unit_id": 9,
				"unit": {
					"id": 9,
					"unit_system_id": 1,
					"unit_type_id": 2,
					"code": "g",
					"name": "grams",
					"desc": null
				},
				"unit_system": {
					"id": 1,
					"code": "metric",
					"name": "Metric",
					"desc": "kg, cm, ml"
				}
			},
			"us": {
				"id": 8,
				"nutrient_id": 4,
				"unit_system_id": 2,
				"unit_id": 11,
				"unit": {
					"id": 11,
					"unit_system_id": 2,
					"unit_type_id": 2,
					"code": "oz",
					"name": "ounce",
					"desc": null
				},
				"unit_system": {
					"id": 2,
					"code": "us",
					"name": "US",
					"desc": "pound, inch, ounce"
				}
			}
		}
	}
};
