<template>
	<div class="bf-sidebar">

		<div class="bf-sidebar__header">
			<slot />
		</div>

		<div class="bf-sidebar__nav">

			<SidebarItems :items="items" />

		</div>

	</div>
</template>

<script>

import "@/assets/scss/components/bf-sidebar.scss";
import SidebarItems from "@/components/view-block/SidebarItems";

export default {
	name: "Sidebar",
	components: { SidebarItems },
	props: {
		items: {
			type: Array,
			default: () => ([]),
		},
	},
	computed: {

	},
	created() {
	},
	data: () => ({}),
	methods: {

	},
};
</script>

<style scoped>

</style>
