import api from "@/api";

export default {
	namespaced: true,
	state: {
		loading: false,

		meal: [],
		dailyNutrients: {},

		foodTypeId: 1,
		favoriteFoods: [],
	},
	mutations: {
		setMeal( state, payload ) {
			state.meal = payload;
		},
		setDailyNutrients( state, payload ) {
			state.dailyNutrients = payload;
		},
		setLoading( state, payload ) {
			state.loading = !!payload;
		},
		setFavoriteFoods(state, payload ) {
			state.favoriteFoods = payload;
		},
		setFoodTypeId(state, payload ) {
			state.foodTypeId = payload;
		},
	},
	getters: {
		loading( state ) {
			return state.loading;
		},
		foodTypeId( state ) {
			return state.foodTypeId;
		},
		meal( state ) {
			return state.meal;
		},
		favoriteFoods( state ) {
			return state.favoriteFoods;
		},
		dailyNutrients( state ) {
			return state.dailyNutrients;
		},
	},
	actions: {
		fetchFavoriteFoods( context, type_id = null ) {
			const query = type_id ? `?type_id=${ type_id }` : "";

			context.commit( "setLoading", true );

			return new Promise( (( resolve, reject ) => {

				api.get( `/food/favorite${ query }` ).then( res => {
					context.commit( "setFavoriteFoods", res.data );
					resolve( res.data );
				} ).catch( err => {

					reject( err );
				} ).finally( () => {

					context.commit( "setLoading", false );
				} );
			}) );
		},
	}
};
